import "../styles/index.scss";

const minIntroLoadTime = 600; // ms

const pageLoad = new Promise((resolve) => {
  window.addEventListener("load", resolve);
});
const timeoutFallback = new Promise((resolve) => {
  setTimeout(resolve, 5000);
});

const minSliderIndex = 0;
const maxSliderIndex = 4;

const initParallaxEffect = () => {
  window.addEventListener("scroll", () => {
    const scrolledPx = window.pageYOffset;
    const parallaxes = Array.from(document.querySelectorAll(".parallax"));

    // You can adjust the 0.1 to change the speed
    const defaultOffset = 0;
    parallaxes.forEach(function (parallax) {
      const offsetInPx = parallax.dataset.offset
        ? +parallax.dataset.offset
        : defaultOffset;

      const coords = offsetInPx + scrolledPx * 0.1 + "px";
      parallax.style.transform = "translateY(" + coords + ")";
    });
  });
};

const initMenuOpening = () => {
  const navbarBurger = document.querySelector(".navbar-burger");
  const body = document.querySelector("body");

  // Handle burger click
  navbarBurger.addEventListener("click", () => {
    navbarBurger.classList.toggle("is-active");
    body.classList.toggle("menu-opened");
  });
};

function addPhoneSliderFunctionality(sliderClass) {
  const slider = document.querySelector(sliderClass);
  const phoneSliderChoices = slider.querySelectorAll(".slider-choice");

  phoneSliderChoices.forEach((choice) => {
    choice.addEventListener("click", () => {
      const previousSelected = slider.querySelector(".is-selected");
      const previousImage = slider.querySelector(".is-active");
      const newImgToSwitch = slider.querySelector(
        `.slider-images #${choice.id}`
      );

      previousSelected.classList.remove("is-selected");
      previousImage.classList.remove("is-active");

      choice.classList.add("is-selected");
      newImgToSwitch.classList.add("is-active");
    });
  });
}

function changeActiveDot(i) {
  const sliderDotNav = document.querySelector(".slider-dot-nav");

  sliderDotNav.querySelector(".dot-active").classList.remove("dot-active");
  sliderDotNav
    .querySelector(`.dot:nth-child(${i + 1})`)
    .classList.add("dot-active");
}

function switchImage(id) {
  const bigSlider = document.querySelector("#big-slider");

  // Hide previous slider image
  bigSlider
    .querySelector(".slider-background-image.is-active")
    .classList.remove("is-active");

  // Hide previous slider content
  bigSlider
    .querySelector(".slider-content.is-active")
    .classList.remove("is-active");

  // Switch to new image and title
  switch (id) {
    case 0:
      bigSlider
        .querySelector(".slider-content#self_guided_tours")
        .classList.add("is-active");
      bigSlider.querySelector(`.image_${id}`).classList.add("is-active");
      break;
    case 1:
      bigSlider
        .querySelector(".slider-content#access_control")
        .classList.add("is-active");
      bigSlider.querySelector(`.image_${id}`).classList.add("is-active");
      break;
    case 2:
      bigSlider
        .querySelector(".slider-content#parking_management")
        .classList.add("is-active");
      bigSlider.querySelector(`.image_${id}`).classList.add("is-active");
      break;
    case 3:
      bigSlider
        .querySelector(".slider-content#asset_protection")
        .classList.add("is-active");
      bigSlider.querySelector(`.image_${id}`).classList.add("is-active");
      break;
    case 4:
      bigSlider
        .querySelector(".slider-content#smart_apartments")
        .classList.add("is-active");
      bigSlider.querySelector(`.image_${id}`).classList.add("is-active");
      break;
  }
}

function addBigSliderDesktopFunctionality() {
  const bigSlider = document.querySelector("#big-slider");
  const leftArrow = bigSlider.querySelector(".arrow-container-left");
  const rightArrow = bigSlider.querySelector(".arrow-container-right");
  let sliderIndex = minSliderIndex;

  leftArrow.addEventListener("click", () => {
    --sliderIndex < 0 ? (sliderIndex = maxSliderIndex) : sliderIndex;
    switchImage(sliderIndex);
  });
  rightArrow.addEventListener("click", () => {
    ++sliderIndex > 4 ? (sliderIndex = minSliderIndex) : sliderIndex;
    switchImage(sliderIndex);
  });
}

function addBigSliderMobileFunctionality() {
  const sliderWrapper = document.querySelector(".slider-wrapper-mobile");
  const N = sliderWrapper.querySelectorAll(".slider-title-mobile").length;
  sliderWrapper.style.setProperty("--n", N);

  const sliderContents = sliderWrapper.querySelectorAll(
    ".slider-content-mobile"
  );

  let multiplier = 0;
  for (const content of sliderContents) {
    content.style.left = `calc(${multiplier * 20}% + 30px)`;
    multiplier++;
  }

  // Unift the touch and click cases
  function unify(e) {
    return e.changedTouches ? e.changedTouches[0] : e;
  }

  let startPosition = null;
  function lock(e) {
    startPosition = unify(e).clientX;
  }

  let i = 0;
  function move(e) {
    if (startPosition || startPosition === 0) {
      let pxDiff = unify(e).clientX - startPosition;
      // negative for swipe from right to left, positive for swipe from left to right
      let swipeDirection = Math.sign(pxDiff);

      if (swipeDirection < 0) {
        if (i < maxSliderIndex) i++;
      } else if (i > 0) i--;

      sliderWrapper.style.setProperty("--i", i);
      startPosition = null;
    }

    changeActiveDot(i);
  }

  sliderWrapper.addEventListener("mousedown", lock, false);
  sliderWrapper.addEventListener("touchstart", lock, false);

  sliderWrapper.addEventListener("mouseup", move, false);
  sliderWrapper.addEventListener("touchend", move, false);
}

document.addEventListener("DOMContentLoaded", () => {
  const body = document.querySelector("body");
  const logoWrapper = document.querySelector(".logo-wrapper");

  requestAnimationFrame(() => {
    logoWrapper.classList.add("logo-animation");
  });

  initParallaxEffect();
  // initMenuOpening();

  Promise.any([pageLoad, timeoutFallback]).then(() => {
    setTimeout(() => {
      logoWrapper.classList.add("logo-animation-over");
      logoWrapper.classList.remove("logo-animation");
      body.classList.remove("show-intro-screen");
    }, minIntroLoadTime);
  });
});

window.addEventListener("load", () => {
  addPhoneSliderFunctionality("#one-solution");

  // addBigSliderDesktopFunctionality();
  // addBigSliderMobileFunctionality();
});
